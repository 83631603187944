.subwork{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.subworkBtn{
    background-color: antiquewhite;
    padding: 1% 1rem;
    border-radius: 2rem;
    margin: 3rem 0;
}

.transition-element {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s;
  transition: 0.5s;
  letter-spacing:0.25rem;

}

.transition-element.visible {

  font-size: larger;
  letter-spacing:0.5rem;
  padding: 2rem;
  opacity: 1;
  visibility: visible;
}