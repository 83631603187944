#contactPage{
    min-height: calc(100vh-4rem);
    width: 100vw;
    max-width: 60rem;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.contactPageTitle{
    font-size: 5rem;
}
.contactDesc{
    color: antiquewhite;
    padding: 1rem;
    font-size: 2rem;
    font-weight: 800;
}
.contactForm{
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    max-width: 60rem;
}
.msg{
    min-height:20vh;
}
.name, .email,.msg{
    font-size: medium;
    width: 100%;
    max-width: 40rem;
    margin: 0.5rem;
    padding: 0.5rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
    background-color: rgb(57, 57, 50);

}
.name:active,.name:focus ,.email:active,.email:focus,.msg:active,.msg:focus{
    border: 3px solid rgb(51, 46, 46);
    box-shadow: 5px 5px 70px rgb(117, 109, 95);
}
.submitBtn{
    background-color: rgb(255, 255, 255);
    border: none;
    padding: 0.75rem 3.5rem;
    color: black;
    border-radius: 0.5rem;
    margin: 2rem;
}
.submitBtn:active{
    background-color: aliceblue;
    letter-spacing: 3px;
}
.links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.clientImg:nth-child(2), .clientImg:nth-child(3)
{
    height: 4rem;
    width: 4rem;

}


.clientImg
{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin: 0 1rem;
    filter: grayscale(30%);
    transition: 0.5s;
    border-radius: 5rem;
  

}
.clientImg:hover{
    
 
  
   box-shadow: 1rem 1rem 70px rgb(101, 101, 91);
    filter: grayscale(50%);
}
.contact{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contactProgress{
    background-color: white;
    height: 3rem;
    width: 1rem;
    display: flex;
}
