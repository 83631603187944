footer{
    width: 100vw;
    height: 4rem;
    background-color: rgb(40, 40, 40 );
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;

}
p{
    font-size: small;
    font-weight: 100;
    color: antiquewhite;
}