#intro{
    height: calc(100vh - 5rem);
    width: 100vw;
    max-width: 75rem;
    margin:0;
    overflow: hidden;
    text-align: center;
}

.bg{
    position: absolute;
    top:5rem;
    right: 0;
    z-index: -1;
    height: 100vh;
    object-fit: fill;
    height:600px;
}
.introContent{
    height:100vh;
    width: 100vw;
    padding:2rem;
    font-size: 3rem;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    justify-content: center;

}
.Hello{

    font-size: 1.75rem;
    font-weight: 100;
}
.introName{
    color: rgba(83, 119, 38, 0.506);
    transition: 0.5s;
}
.introName:active{
    color: rgb(244, 244, 203);
}


.introPara{
    font-size: medium;
    font-weight: 300;
    letter-spacing: 1px;
}
.btn{
    background-color: rgb(255, 255, 255);
    margin:1rem 0;
    padding: 0.75rem 1.5rem;
    border-radius: 2rem;
    
}
.btnImg{
    object-fit: cover;
    margin: 0 0.25rem;
    height: 1rem;
}

@media screen and (max-width:630px) {
    .bg{
       
       right: -10vw;

    }
    .introContent
    {
        font-size: 9vw;
    }
    
    
}
@media screen and (max-width:540px) {
    .bg{
        right: -50vw;
    }
    .introName{
        color: rgba(255, 223, 40, 0.506);
        transition: 0.5s;
    }
    

}
@media screen and (max-width:400px) {
    .bg{
        right: -7 0vw;
    }
   
    

}

