#works{
    margin: 20px auto;
    min-height: calc(100vh-4rem);
    width: 100vw;
    max-width: 60rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;

}
.workstitle{
  
    margin: 2rem 0;
    font-size: 3rem;
    font-weight: 700;
}
.worksDesc{
    font-size:300;
    font-weight: 1rem;
    margin-bottom: 2rem;
    max-width: 45rem;
}
.worksImg{
    object-fit: cover;
    width: 18rem;
    margin: 0.5rem;
    border-radius: 50px;
    filter: blur(2px);
    opacity: 0.5;
    
}
.worksImgs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    max-width: 55rem;
    flex-wrap: wrap;
    margin: auto;
    
}
.workBtn
{
    margin: 3rem 0;
    padding: 0.5rem 2.5rem;
    border :none;
    background-color: white;
    border-radius: 2rem;
    font-size:1rem ;
}

.containerImg{
    width: 18rem;
    position:relative;
    text-align: center;
    margin: 0.7rem;
   
}
.containerImgText{
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-weight: bolder;
    font-size: 1.5vw;
    transition: 0.5s;

}
.containerImgText:hover{
    color: antiquewhite;
    font-size: 1.7vw;

}

@media screen and (max-width:585px) {
    .worksImg{
        height: 48vw;
        border-radius: 25px;
    }
    
}

.workBtnText{
    
}